import Vue from 'vue'
import Vuex from 'vuex'
import account from './modules/account'
import auth from './modules/auth'
import calendars from './modules/calendars'
import commondays from './modules/commondays'
import days from './modules/days'
import dialog from './modules/dialog'
import menu from './modules/menu'
import months from './modules/months'
import snackbar from './modules/snackbar'
import years from './modules/years'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account,
    auth,
    calendars,
    commondays,
    days,
    dialog,
    menu,
    months,
    snackbar,
    years
  }
})
