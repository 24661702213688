<template>
  <v-main>
    <v-container @keyup.enter="register()" fill-height>
      <v-row justify="center">
        <v-col xl="5" lg="7" md="9" sm="11">
          <v-form lazy-validation ref="registrationForm" v-model="formValid">
            <v-text-field label="ФИО"
              v-model.trim="user.name"
              autocomplete="name"
              :rules="[ v => !!v || 'Пожалуйста, заполните это поле']">
            </v-text-field>
            <v-text-field label="Email"
              v-model.trim="user.email"
              autocomplete="email"
              :rules="[ v => !!v || 'Пожалуйста, заполните это поле', v => /.+@.+\..+/.test(v) || 'Пожалуйста, укажите правильный email' ]">
            </v-text-field>
            <v-text-field label="Пароль"
              v-model="user.password"
              autocomplete="new-password"
              :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              :rules="[v => !!v || 'Пожалуйста, заполните это поле']">
            </v-text-field>
            <v-text-field label="Пароль ещё раз"
              v-model="user.password_confirmation"
              autocomplete="new-password"
              :append-icon="showPasswordAgain ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              :type="showPasswordAgain ? 'text' : 'password'"
              @click:append="showPasswordAgain = !showPasswordAgain"
              :rules="[v => !!v || 'Пожалуйста, заполните это поле', v => v === user.password || 'Пароли не совпадают']">
            </v-text-field>
            <v-btn @click.stop="register()" :disabled="!formValid" color="primary" class="mt-3">Зарегистрироваться</v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data () {
    return {
      user: {
      },
      showPassword: false,
      showPasswordAgain: false,
      formValid: true
    }
  },
  computed: {
    menu: {
      get: function () {
        return this.$store.getters.menuIsOpen
      },
      set: function (value) {
        this.$store.commit('MENU_SET', value)
      }
    }
  },
  methods: {
    register: function () {
      if (this.$refs.registrationForm.validate()) {
        this.$store.dispatch('ACCOUNT_REGISTER', this.user).then(() => {
          if (this.$store.getters.accountStatus === 'success') {
            this.$store.commit('SNACKBAR_SET', 'Для завершения регистрации вам отправлено письмо.')
            this.$router.push('/login/')
          } else this.$store.commit('SNACKBAR_SET', 'Пользователь с таким email уже зарегистрирован')
        }, (err) => {
          this.$store.commit('SNACKBAR_SET', 'Произошла ошибка: ' + err)
        })
      }
    }
  },
  mounted: function () {
  }
}
</script>

<style scoped>
</style>
