<template>
  <br>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {
    emailConfirm: function () {
      if (this.$router.history.current.query.code) {
        let data = {
          email: this.$router.history.current.query.email,
          code: this.$router.history.current.query.code
        }
        this.$store.dispatch('ACCOUNT_EMAIL_CONFIRM', data).then(() => {
          if (this.$store.getters.accountStatus === 'success') {
            if (this.$store.getters.isAuthenticated) this.$store.commit('SNACKBAR_SET', 'Email успешно подтверждён. Вход выполнен.')
            else this.$store.commit('SNACKBAR_SET', 'Email успешно подтверждён')
          }
        }, (err) => {
          err = ''
          this.$store.commit('SNACKBAR_SET', 'Email не подтверждён' + err)
        })
        if (this.$store.getters.isAuthenticated) this.$router.push('/account/')
        else this.$router.push('/login/')
      }
    }
  },
  mounted: function () {
    this.emailConfirm()
  }
}
</script>

<style scoped>
</style>
