const state = { opened: null }

const getters = {
  menuIsOpen: state => !!state.opened
}

const mutations = {
  ['MENU_SET'] (state, opened) {
    state.opened = opened
  }
}

export default {
  state,
  getters,
  mutations
}
