<template>
  <v-main class="bg">
    <v-container fill-height>
      <v-row justify="center">
        <v-col xl="5" lg="7" md="9" sm="11" class="bg-white px-10 pt-10">
          <v-row>
            <v-col cols="12" sm="8" class="pb-0">
              <h1 class="text-center text-sm-left">Живи в&nbsp;ногу со&nbsp;временем</h1>
            </v-col>
            <v-col cols="12" sm="4" class="justify-center pb-0">
              <v-img src="../assets/logo.png" alt="" class="mb-3" />
           </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col xl="5" lg="7" md="9" sm="11" class="bg-white waves px-10">
          <v-row>
            <v-col cols="12" sm="8" class="py-0 d-flex align-center">
              <blockquote>
                <p>«Проживая жизнь через личные энергии дня, месяца и&nbsp;года, мы&nbsp;приходим к&nbsp;гармонии, самореализации и&nbsp;развитию своего сознания»</p>
                <cite>Жанат Кожамжаров</cite>
              </blockquote>
            </v-col>
            <v-col cols="12" sm="4" class="justify-center py-0">
              <v-img src="../assets/zhanat.png" class="s-photo" alt="Энвер Фаткуллин" />
           </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col xl="5" lg="7" md="9" sm="11" class="bg-white px-10">
          <v-row class="src-data">
            <v-col cols="12" lg="6" class="py-0">ФИО</v-col>
            <v-col cols="12" lg="6" class="font-weight-bold text-lg-right py-0">{{ calendarData.name }}</v-col>
          </v-row>
          <v-row class="src-data">
            <v-col cols="12" lg="6" class="py-0">Дата рождения</v-col>
            <v-col cols="12" lg="6" class="font-weight-bold text-lg-right py-0">{{ $moment(calendarData.birthDate).format('DD.MM.YYYY') }}</v-col>
          </v-row>
          <v-row class="src-data">
            <v-col cols="12" lg="6" class="py-0">Число сознания</v-col>
            <v-col cols="12" lg="6" class="font-weight-bold text-lg-right py-0">{{ calendarData.senseNumber }}</v-col>
          </v-row>
          <v-row class="src-data">
            <v-col cols="12" lg="6" class="py-0">Миссия</v-col>
            <v-col cols="12" lg="6" class="font-weight-bold text-lg-right py-0">{{ calendarData.mission }}</v-col>
          </v-row>
          <v-row class="src-data">
            <v-col cols="12" lg="6" class="py-0 font-weight-bold fs-125">Текущая дата</v-col>
            <v-col cols="12" lg="6" class="font-weight-bold text-lg-right py-0">
              <v-row>
                <v-col cols="3">
                  <v-row class="flex-column">
                    <v-col class="d-flex">
                      <v-btn depressed block class="btn-date" :disabled="dayPlusDisabled || btnDisabled" @click="dayPlus()"><v-icon>mdi-plus-thick</v-icon></v-btn>
                    </v-col>
                    <v-col class="d-flex justify-center py-0">
                      <div class="date-value">{{ currentDay }}</div>
                    </v-col>
                    <v-col class="d-flex">
                      <v-btn depressed block class="btn-date" :disabled="dayMinusDisabled || btnDisabled" @click="dayMinus()"><v-icon>mdi-minus-thick</v-icon></v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row class="flex-column">
                    <v-col class="d-flex">
                      <v-btn depressed block class="btn-date" :disabled="monthPlusDisabled || btnDisabled" @click="monthPlus()"><v-icon>mdi-plus-thick</v-icon></v-btn>
                    </v-col>
                    <v-col class="d-flex justify-center py-0">
                      <div class="date-value">{{ currentMonth }}</div>
                    </v-col>
                    <v-col class="d-flex">
                      <v-btn depressed block class="btn-date" :disabled="monthMinusDisabled || btnDisabled" @click="monthMinus()"><v-icon>mdi-minus-thick</v-icon></v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="3">
                  <v-row class="flex-column">
                    <v-col class="d-flex">
                      <v-btn depressed block class="btn-date" :disabled="yearPlusDisabled || btnDisabled" @click="yearPlus()"><v-icon>mdi-plus-thick</v-icon></v-btn>
                    </v-col>
                    <v-col class="d-flex justify-center py-0">
                      <div class="date-value">{{ currentYear }}</div>
                    </v-col>
                    <v-col class="d-flex">
                      <v-btn depressed block class="btn-date" :disabled="yearMinusDisabled || btnDisabled" @click="yearMinus()"><v-icon>mdi-minus-thick</v-icon></v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-0">
        <v-col xl="5" lg="7" md="9" sm="11" class="bg-white pt-0 pb-5 px-5">
          <div class="card card-shadow logo-right pa-5">
            <h2 class="d-flex justify-space-between">Ваш личный день <span>{{ calendarData.personalDay.id }}</span></h2>
            <p class="in-plus" v-html="calendarData.personalDay.in_plus"></p>
            <p class="in-minus" v-html="calendarData.personalDay.in_minus"></p>
          </div>
        </v-col>
      </v-row>
      <!--
      <v-row justify="center">
        <v-col xl="5" lg="7" md="9" sm="11" class="bg-white pt-0 px-5">
          <div class="card pa-5">
            <h2 class="d-flex justify-space-between">Ваш общий день <span>{{ calendarData.commonDay.id }}</span></h2>
            <p v-html="calendarData.commonDay.descr"></p>
          </div>
        </v-col>
      </v-row>
      -->
      <v-row justify="center" class="mt-0">
        <v-col xl="5" lg="7" md="9" sm="11" class="bg-white pt-0 pb-5 px-5">
          <div class="card card-shadow logo-left pa-5">
            <h2 class="d-flex justify-space-between">Ваш личный месяц <span>{{ calendarData.personalMonth.id }}</span></h2>
            <p class="in-plus" v-html="calendarData.personalMonth.in_plus"></p>
            <p class="in-minus" v-html="calendarData.personalMonth.in_minus"></p>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col xl="5" lg="7" md="9" sm="11" class="bg-white pt-0 px-5">
          <div class="card pa-5">
            <h2 class="d-flex justify-space-between">Ваш личный год <span>{{ calendarData.personalYear.id }}</span></h2>
            <p class="in-plus" v-html="calendarData.personalYear.in_plus"></p>
            <p class="in-minus" v-html="calendarData.personalYear.in_minus"></p> 
          </div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col xl="5" lg="7" md="9" sm="11" class="bg-white pt-0 px-5">
          <h2 class="text-center font-weight-black">Обзор месяца</h2>
          <div class="month-changer d-flex justify-center">
            <v-btn x-large icon @click="prevMonth()" :disabled="monthPrevDisabled || btnDisabled"><v-icon>mdi-menu-left</v-icon></v-btn>
            <span>{{ currentMonthDetailed }}</span>
            <v-btn x-large icon @click="nextMonth()" :disabled="monthNextDisabled || btnDisabled"><v-icon>mdi-menu-right</v-icon></v-btn>
          </div>
          <div class="month-table-container">
            <table class="month-table">
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>Пн</th>
                  <th>Вт</th>
                  <th>Ср</th>
                  <th>Чт</th>
                  <th>Пт</th>
                  <th>Сб</th>
                  <th class="sunday">Вс</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(week, i) in monthToDisplay" :key="'w' + i">
                  <td>
                    <v-row class="flex-column days-legend">
                      <v-col class="text-right pt-6 pr-5">личный день</v-col>
                      <v-col class="text-right pr-5">общий день</v-col>
                    </v-row>
                  </td>
                  <td v-for="(day, j) in week" :key="j">
                    <v-row class="flex-column days-info">
                      <v-col class="text-center font-weight-bold mb-1"><div :class="'d-' + day.color">{{ day.day }}</div></v-col>
                      <!--<v-col class="text-center"><span :class="getClass(day.personalDay)">{{ day.personalDay }}</span></v-col>-->
                      <v-col class="text-center"><span>{{ day.personalDay }}</span></v-col>
                      <v-col class="text-center"><span :class="getClass(day.commonDay)">{{ day.commonDay }}</span></v-col>
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-end">
              <div>
                <p><!--<span class="day-legend day-three">3</span><span class="day-legend day-six">6</span>--><span class="day-legend day-eight">8</span> Эффективные дни</p>
                <p><span class="period-legend d-green">+</span> Благоприятные периоды</p>
                <p><span class="period-legend d-red">-</span> Неблагоприятные периоды</p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <!--
      <v-row justify="center">
        <v-col xl="5" lg="7" md="9" sm="11" class="bg-white pt-0 px-5">
          <div class="card pa-5">
            <h2>Прошедшие 5 лет:</h2>
            <v-row v-for="year in calendarData.lastYears" :key="year.year" class="align-center">
              <v-col cols="12" lg="2" md="3"><h3>{{ year.year }} год</h3></v-col>
              <v-col cols="12" lg="10" md="9">
                <p class="in-plus">{{ year.in_plus }}</p>
                <p class="in-minus">{{ year.in_minus }}</p>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      -->
      <v-row justify="center">
        <v-col xl="5" lg="7" md="9" sm="11" class="bg-white pt-0 px-5">
          <div class="card pa-5">
            <h2>Предстоящий год:</h2>
            <v-row v-for="year in calendarData.nextYears" :key="year.year" class="align-center">
              <v-col cols="12" lg="2" md="3"><h3>{{ year.year }} год</h3></v-col>
              <v-col cols="12" lg="10" md="9">
                <p class="in-plus" v-html="year.in_plus"></p>
                <p class="in-minus" v-html=" year.in_minus"></p>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data () {
    return {
      calendarData: {
        personalDay: {},
        commonDay: {},
        personalMonth: {},
        personalYear: {},
        last5Years: {},
        next5Years: {},
        month: {}
      },
      options: {},
      monthToDisplay: [],
      btnDisabled: false
    }
  },
  computed: {
    currentDay: function () {
      return this.$moment(this.calendarData.userDate).format('DD') || ''
    },
    currentMonth: function () {
      return this.$moment(this.calendarData.userDate).format('DD MMMM').substring(3) || ''
    },
    currentYear: function () {
      return this.$moment(this.calendarData.userDate).format('YYYY') || ''
    },
    dayMinusDisabled: function () {
      return this.$moment(this.calendarData.userDate).subtract(1, 'd').isBefore(this.calendarData.dateSince)
    },
    dayPlusDisabled: function () {
      return this.$moment(this.calendarData.userDate).add(1, 'd').isAfter(this.calendarData.dateTill)
    },
    monthMinusDisabled: function () {
      return this.$moment(this.calendarData.userDate).endOf('month').subtract(1, 'M').isBefore(this.calendarData.dateSince)
    },
    monthPlusDisabled: function () {
      return this.$moment(this.calendarData.userDate).startOf('month').add(1, 'M').isAfter(this.calendarData.dateTill)
    },
    yearMinusDisabled: function () {
      return this.$moment(this.calendarData.userDate).endOf('year').subtract(1, 'y').isBefore(this.calendarData.dateSince)
    },
    yearPlusDisabled: function () {
      return this.$moment(this.calendarData.userDate).startOf('year').add(1, 'y').isAfter(this.calendarData.dateTill)
    },
    currentMonthDetailed: function () {
      return this.$moment(this.calendarData.userMonth).format('MMMM') || ''
    },
    monthPrevDisabled: function () {
      return this.$moment(this.calendarData.userMonth).endOf('month').subtract(1, 'M').isBefore(this.calendarData.dateSince)
    },
    monthNextDisabled: function () {
      return this.$moment(this.calendarData.userMonth).startOf('month').add(1, 'M').isAfter(this.calendarData.dateTill)
    },
   },
  methods: {
    getCalendar: function () {
      this.btnDisabled = true
      this.$store.dispatch('CALENDARS_GET_CALENDAR', { hash: this.$route.params.hash, options: this.options || {} }).then(() => {
        if (this.$store.getters.calendarsStatus === 'success') {
          this.calendarData = this.$store.getters.calendar
          this.btnDisabled = false
          this.getMonthToDisplay()
        } else {
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
          this.$router.push('/')
        }
      }, (err) => {
        err = ''
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
        this.$router.push('/')
      })
    },
    getMonth: function () {
      this.btnDisabled = true
      this.$store.dispatch('CALENDARS_GET_CALENDAR', { hash: this.$route.params.hash, options: this.options || {} }).then(() => {
        if (this.$store.getters.calendarsStatus === 'success') {
          this.calendarData.month = this.$store.getters.calendar.month
          this.calendarData.monthShift = this.$store.getters.calendar.monthShift
          this.calendarData.userMonth = this.$store.getters.calendar.userMonth
          this.btnDisabled = false
          this.getMonthToDisplay()
        } else {
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
          this.$router.push('/')
        }
      }, (err) => {
        err = ''
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
        this.$router.push('/')
      })
    },
    dayPlus: function () {
      this.options = {
        userDate: this.$moment(this.calendarData.userDate).add(1, 'd').format('YYYY-MM-DD')
      }
      this.getCalendar()
    },
    dayMinus: function () {
      this.options = {
        userDate: this.$moment(this.calendarData.userDate).subtract(1, 'd').format('YYYY-MM-DD')
      }
      this.getCalendar()
    },
    monthPlus: function () {
      this.options = {
        userDate: this.$moment(this.calendarData.userDate).add(1, 'M').format('YYYY-MM-DD')
      }
      this.getCalendar()
    },
    monthMinus: function () {
      this.options = {
        userDate: this.$moment(this.calendarData.userDate).subtract(1, 'M').format('YYYY-MM-DD')
      }
      this.getCalendar()
    },
    yearPlus: function () {
      this.options = {
        userDate: this.$moment(this.calendarData.userDate).add(1, 'y').format('YYYY-MM-DD')
      }
      this.getCalendar()
    },
    yearMinus: function () {
      this.options = {
        userDate: this.$moment(this.calendarData.userDate).subtract(1, 'y').format('YYYY-MM-DD')
      }
      this.getCalendar()
    },
    getMonthToDisplay: function () {
      this.monthToDisplay = []
      this.monthToDisplay[0] = []
      for (let j = 0; j < this.calendarData.monthShift; j++) {
        this.monthToDisplay[0].push({})
      }
      this.calendarData.month.forEach(function (day, index) {
        let i = parseInt((this.calendarData.monthShift + index) / 7)
        this.monthToDisplay[i] = this.monthToDisplay[i] || []
        this.monthToDisplay[i].push(day)
      }, this)
    },
    prevMonth: function () {
      this.options = {
        userMonth: this.$moment(this.calendarData.userMonth).subtract(1, 'M').format('YYYY-MM-DD')
      }
      this.getMonth()
    },
    nextMonth: function () {
      this.options = {
        userMonth: this.$moment(this.calendarData.userMonth).add(1, 'M').format('YYYY-MM-DD')
      }
      this.getMonth()
    },
    getClass: function (day) {
      switch (day) {
        case 3:
          // return 'day-three'
          return 'day-eight'
        case 6:
          // return 'day-six'
          return 'day-eight'
        case 8:
          return 'day-eight'
      }
      return ''
    }
  },
  mounted: function () {
    if (!this.$route.params) this.$router.push('/')
    else this.getCalendar()
  }
}
</script>

<style scoped>
.bg {
  background-image: url('../assets/bg.jpg');
  background-size: cover;
}
.bg-white {
  background-color: #fff;
}
.waves {
  background-image: url('../assets/bg-waves.jpg');
  background-position: center bottom;
  background-size: contain;
}
h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 3rem;
  color: #00707a;
  line-height: 1.1;
  margin-top: 2rem;
}
p, div, blockquote {
  color: #448086;
}
cite {
  display: block;
  font-weight: 700;
  font-style: normal;
  text-align: right;
}
.s-photo {
  margin-bottom: 33px;
  border-radius: .75rem;
  -webkit-box-reflect: below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(250, 250, 250, 0.1)));
}
.src-data {
  font-size: 1.125rem;
  margin-top: 0;
  margin-bottom: 1rem;
}
.btn-date {
  height: 2rem!important;
  color: #448086;
  background-color: #ffead7!important;
  border-radius: .5rem;
}
.date-value {
  font-size: 1.25rem;
  border: 2px solid #ffead7;
  border-radius: .5rem;
  width: 100%;
  text-align: center;
}
.fs-125 {
  font-size: 1.25rem;
}
.card {
  padding-bottom: 1.25rem;
}
.card-shadow {
  box-shadow: 0 0 25px 0 rgba(23, 44, 46, .15);
}
.logo-right {
  background: url('../assets/bg-right.svg') right center no-repeat;
  background-size: auto;
}
.logo-left {
  background: url('../assets/bg-left.svg') left center no-repeat;
  background-size: auto;
}
.card h2 {
  font-weight: 900;
  font-size: 1.25rem;
  margin-bottom: 1.25rem;
}
.card p {
  padding: 1.25rem 0 1.25rem 2.875rem;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.card p.in-plus {
  background: url('../assets/in-plus.svg') left center no-repeat;
  background-size: 2rem;
}
.card p.in-minus {
  background: url('../assets/in-minus.svg') left center no-repeat;
  background-size: 2rem;
}
.month-changer {
  margin-top: 2rem;
}
.month-changer .v-icon {
  color: #ff3232;
}
.month-changer span {
  font-size: 2rem;
  font-weight: 900;
  padding: 0 1rem;
  min-width: 13rem;
  text-align: center;
}
.month-changer span:first-letter {
  text-transform: uppercase;
}
.days-legend {
  font-size: .875rem;
  margin-top: 0;
  margin-bottom: 1rem;
}
.days-info {
  margin-top: 0;
  margin-bottom: 1rem;
}
.month-table tr:first-child .days-legend,
.month-table tr:first-child .days-info {
  margin-top: .5rem;
}
.month-table td {
  line-height: 1.5rem;
  min-width: 2.5rem;
}
.month-table-container {
  padding-right: 5rem;
}
.month-table {
  margin: 2rem 0;
  width: 100%;
  border-collapse: collapse;
}
.month-table th {
  font-weight: 300;
  text-align: center;
  padding-bottom: .5rem;
  border-bottom: 2px solid #448086;
}
.month-table th:first-child {
  border-bottom: 2px solid transparent;
}
.sunday,
.month-table td:nth-child(8) div.col:first-child {
  color: #ff3232;
}
.month-table div.col {
  padding-top: 0;
  padding-bottom: 0;
}
.month-table td span {
  display: block;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 100%;
  margin: auto;
  text-align: center;
}
.day-three {
  border: 1px solid #f9b256;
}
.day-six {
  border: 1px solid #cc72e6;
}
.day-eight {
  border: 1px solid #0084ff;
}
.day-legend {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-width: 2px;
  border-radius: 100%;
  margin-right: .5rem;
  color: transparent;
}
.period-legend {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-width: 2px;
  margin-right: .5rem;
  color: transparent;
}
.d-green {
  background: url('../assets/d-green.svg') 0 0 repeat;
  background-size: 1.5rem;
  background-attachment: fixed;
}
.d-red {
  background: url('../assets/d-red.svg') 0 0 repeat;
  background-size: 1.5rem;
  background-attachment: fixed;
}
@media screen and (min-width: 600px)
{
  h1 {
    font-size: 2.7rem;
  }
  .s-photo {
    margin-bottom: 31px;
  }
  .month-table-container {
    padding-right: 7rem;
  }
  .month-table td {
    min-width: 3rem;
  }
}
@media screen and (min-width: 700px)
{
  .s-photo {
    margin-bottom: 35px;
  }
  .month-table-container {
    padding-right: 8rem;
  }
}
@media screen and (min-width: 800px)
{
  .s-photo {
    margin-bottom: 40px;
  }
  .month-table-container {
    padding-right: 8.5rem;
  }
}
@media screen and (min-width: 900px)
{
  .s-photo {
    margin-bottom: 45px;
  }
  .month-table-container {
    padding-right: 9rem;
  }
}
@media screen and (min-width: 960px)
{
  h1 {
    font-size: 3.4rem;
  }
  .s-photo {
    margin-bottom: 38px;
  }
  .month-table-container {
    padding-right: 6.5rem;
  }
  .month-table td {
    min-width: 3.5rem;
  }
}
@media screen and (min-width: 1264px)
{
  .month-table-container {
    padding-right: 6rem;
  }
  .month-table td {
    min-width: 4rem;
  }
}
@media screen and (min-width: 1904px)
{
  .s-photo {
    margin-bottom: 42px;
  }
  .month-table-container {
    padding-right: 7rem;
  }
  .month-table td {
    min-width: 3.5rem;
  }
}
</style>
