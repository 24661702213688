<template>
  <v-navigation-drawer v-model="menu" app width="400" temporary>
    <p class="text-center">
      <img src="../assets/logo.png" alt="Zhanat" style="max-width: 200px;" class="mt-3 mb-1" />
    </p>
    <v-list>
      <v-list-item-group :mandatory="mandatoryIndex">
        <v-list-item link :to="page.link" v-for="page in pages" :key="page.link">
          <v-list-item-icon>
            <v-icon>{{ page.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ page.label }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data () {
    return {
      mandatoryIndex: false,
    }
  },
  computed: {
    menu: {
      get: function () {
        return this.$store.getters.menuIsOpen
      },
      set: function (value) {
        this.$store.commit('MENU_SET', value)
      }
    },
    pages: function () {
      let pages = [
        {
          label: 'Мои календари',
          link: '/calendars/',
          icon: 'mdi-calendar-multiple-check',
          auth: true
        },
        {
          label: 'Дни',
          link: '/days/',
          icon: 'mdi-calendar',
          auth: true
        },
        {
          label: 'Общие дни',
          link: '/common-days/',
          icon: 'mdi-calendar-outline',
          auth: true
        },
        {
          label: 'Месяцы',
          link: '/months/',
          icon: 'mdi-calendar-month',
          auth: true
        },
        {
          label: 'Годы',
          link: '/years/',
          icon: 'mdi-calendar-blank-multiple',
          auth: true
        },
        {
          label: 'Выход',
          link: '/logout/',
          icon: 'mdi-logout',
          auth: true
        }
      ]
      return pages.filter(page => (page.auth === this.$store.getters.isAuthenticated))
    }
  },
  watch: {
    $route: function (to) {
      this.getPage(to)
    }
  },
  methods: {
    getPage: function (route) {
      this.mandatoryIndex = false
      this.pages.forEach(function (page) {
        if (page.link === route.path) {
          this.mandatoryIndex = true
        }
      }, this)
    }
  },
  mounted: function () {
    this.getPage(this.$router.currentRoute)
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&display=swap');
.v-application {
  font-family: 'Montserrat', sans-serif!important;
}
</style>
