import Vue from 'vue'
import Router from 'vue-router'
import CalendarEditForm from '@/components/CalendarEditForm'
import CalendarPage from '@/components/CalendarPage'
import CalendarsPage from '@/components/CalendarsPage'
import CommonDaysPage from '@/components/CommonDaysPage'
import CommonDaysEditForm from '@/components/CommonDaysEditForm'
import DaysPage from '@/components/DaysPage'
import DaysEditForm from '@/components/DaysEditForm'
import EmailConfirmPage from '@/components/EmailConfirmPage'
import HomePage from '@/components/HomePage'
import LoginPage from '@/components/LoginPage'
import LogoutPage from '@/components/LogoutPage'
import MonthsPage from '@/components/MonthsPage'
import MonthsEditForm from '@/components/MonthsEditForm'
import RegisterPage from '@/components/RegisterPage'
import YearsPage from '@/components/YearsPage'
import YearsEditForm from '@/components/YearsEditForm'

import store from '../store'

Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login/')
}

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/register/',
      name: 'RegisterPage',
      component: RegisterPage,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/login/',
      name: 'LoginPage',
      component: LoginPage,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/logout/',
      name: 'LogoutPage',
      component: LogoutPage,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/email-confirm/',
      name: 'EmailConfirmPage',
      component: EmailConfirmPage
    },
    {
      path: '/calendars/edit/',
      name: 'CalendarEditForm',
      component: CalendarEditForm,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/calendars/',
      name: 'CalendarsPage',
      component: CalendarsPage,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/common-days/',
      name: 'CommonDaysPage',
      component: CommonDaysPage,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/common-days/edit/',
      name: 'CommonDaysEditForm',
      component: CommonDaysEditForm,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/days/',
      name: 'DaysPage',
      component: DaysPage,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/days/edit/',
      name: 'DaysEditForm',
      component: DaysEditForm,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/months/',
      name: 'MonthsPage',
      component: MonthsPage,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/months/edit/',
      name: 'MonthsEditForm',
      component: MonthsEditForm,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/years/',
      name: 'YearsPage',
      component: YearsPage,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/years/edit/',
      name: 'YearsEditForm',
      component: YearsEditForm,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/calendar/:hash',
      name: 'CalendarPage',
      component: CalendarPage
    },
    {
      path: '*',
      name: 'HomePage',
      component: HomePage
    }
  ]
})
