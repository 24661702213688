import router from '../../router'
import apiCall from '../../plugins/api'

const state = {
  status: '',
  link: '',
  calendar: {},
  calendars: {},
  bottomsheetOpened: false
}

const getters = {
  calendarsStatus: state => state.status,
  isCalendarsBottomsheetOpen: state => !!state.bottomsheetOpened,
  calendarLink: state => state.link,
  calendar: state => state.calendar,
  calendars: state => state.calendars
}

const actions = {
  ['CALENDARS_GET']: ({ commit, dispatch }, options) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/calendars/all', data: options , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('CALENDARS_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('CALENDARS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('CALENDARS_ERROR')
          resolve(err)
        })
    })
  },
  ['CALENDARS_UPDATE']: ({ commit, dispatch }, calendar) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/calendars/edit', data: calendar , method: 'POST' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('CALENDARS_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('CALENDARS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('CALENDARS_ERROR')
          resolve(err)
        })
    })
  },
  ['CALENDARS_GET_CALENDAR']: ({ commit }, data) => {
    return new Promise((resolve) => {
      apiCall({ url: '/api/calendar/' + data.hash, data: data.options , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('CALENDARS_SUCCESS', resp)
            resolve(resp)
          } else {
            commit('CALENDARS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('CALENDARS_ERROR')
          resolve(err)
        })
    })
  },
  ['CALENDARS_REMOVE']: ({ commit, dispatch }, calendar) => {
    return new Promise((resolve, reject) => {
      commit('CALENDARS_REMOVE')
      apiCall({ url: '/api/calendars/del', data: calendar, method: 'POST' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('CALENDARS_REMOVE_SUCCESS')
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('CALENDARS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('CALENDARS_ERROR', err)
          reject(err)
        })
    })
  },
  ['CALENDARS_SET']: ({ commit }, calendar) => {
    return new Promise((resolve) => {
      commit('CALENDARS_SET', calendar)
      resolve()
    })
  },
  ['CALENDARS_BOTTOMSHEET_OPEN']: ({ commit }, calendarId) => {
    return new Promise((resolve) => {
      commit('CALENDARS_BOTTOMSHEET_OPEN', calendarId)
      resolve()
    })
  }
}

const mutations = {
  ['CALENDARS_SUCCESS']: (state, resp) => {
    state.status = 'success'
    state.link = resp.link || ''
    state.calendar = resp.calendar || {}
    state.calendars = resp.calendars || {}
  },
  ['CALENDARS_ERROR']: (state) => {
    state.status = 'error'
  },
  ['CALENDARS_UPDATE']: (state) => {
    state.status = 'updating'
  },
  ['CALENDARS_REMOVE']: (state) => {
    state.status = 'removing'
  },
  ['CALENDARS_UPDATE_SUCCESS']: (state) => {
    state.status = 'success'
  },
  ['CALENDARS_REMOVE_SUCCESS']: (state) => {
    state.status = 'success'
  },
  ['CALENDARS_SET']: (state, calendar) => {
    state.calendar = calendar
  },
  ['CALENDARS_BOTTOMSHEET_OPEN']: (state, calendar) => {
    state.bottomsheetOpened = true
    state.calendar = calendar
  },
  ['CALENDARS_BOTTOMSHEET_CLOSE']: (state) => {
    state.bottomsheetOpened = false
  },
  ['CALENDARS_BOTTOMSHEET_CLEAN']: (state) => {
    state.calendar = {}
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
