<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="menu = !menu">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-toolbar-title>Дни</v-toolbar-title>
    </v-app-bar>
    <v-container>
      <v-data-table
        :headers="listHeaders"
        :items="listData"
        :loading="dataLoading"
        hide-default-footer>
        <template v-slot:[`item.in_plus`]="{ item }">
          <div v-html="item.in_plus"></div>
        </template>
        <template v-slot:[`item.in_minus`]="{ item }">
          <div v-html="item.in_minus"></div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon @click="editObj(item)">mdi-square-edit-outline</v-icon>
        </template>
      </v-data-table>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data () {
    return {
      listHeaders: [
        { text: 'Номер', value: 'id', sortable: false, filterable: false },
        { text: 'В плюсе', value: 'in_plus', sortable: false, filterable: false },
        { text: 'В минусе', value: 'in_minus', sortable: false, filterable: false },
        { text: '', value: 'actions', sortable: false, filterable: false }
      ],
      listData: [],
      dataLoading: false
    }
  },
  computed: {
    menu: {
      get: function () {
        return this.$store.getters.menuIsOpen
      },
      set: function (value) {
        this.$store.commit('MENU_SET', value)
      }
    }
  },
  methods: {
    loadData: function () {
      this.dataLoading = true
      this.$store.dispatch('DAYS_GET').then(() => {
        this.dataLoading = false
        if (this.$store.getters.daysStatus === 'success') {
          this.listData = this.$store.getters.days
        } else {
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
        }
      }, (err) => {
        this.dataLoading = false
        err = ''
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
      })
    },
    editObj: function (obj) {
      this.$store.dispatch('DAYS_SET', obj).then(() => {
        this.$router.push('/days/edit/')
      })
    }
  },
  mounted: function () {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
