<template>
  <v-main>
    <v-container fill-height>
      <v-row justify="center">
        <v-col>Сообщение с контактами для обращения</v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
  mounted: function () {
    if (this.$store.getters.isAuthenticated) this.$router.push('/calendars/')
  }
}
</script>

<style scoped>

</style>
