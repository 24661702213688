import router from '../../router'
import apiCall from '../../plugins/api'

const state = {
  status: '',
  bottomsheetOpened: false,
  user: {},
}

const getters = {
  accountStatus: state => state.status,
  user: state => state.user,
}

const actions = {
  ['ACCOUNT_REGISTER']: ({ commit }, account) => {
    return new Promise((resolve, reject) => {
      commit('ACCOUNT_JOIN')
      apiCall({ url: '/api/register', data: account, method: 'POST' })
        .then(resp => {
          if (resp.status === 'accountOK') {
            commit('ACCOUNT_JOIN_SUCCESS')
            resolve(resp)
          } else {
            commit('ACCOUNT_ERROR', resp)
            reject(resp)
          }
        })
        .catch(err => {
          if (err.email !== undefined) {
            commit('ACCOUNT_EMAIL_ERROR')
          }
          resolve(err)
        })
    })
  },
  ['ACCOUNT_EMAIL_CONFIRM']: ({ commit, dispatch }, emailObj) => {
    return new Promise((resolve, reject) => {
      commit('ACCOUNT_EMAIL_CONFIRM')
      apiCall({ url: '/api/email-confirm', data: emailObj, method: 'POST' })
        .then(resp => {
          if (resp.status === 'emailOK') {
            commit('ACCOUNT_UPDATE_SUCCESS')
            resolve(resp)
          } else if (resp.status === 'emailError') {
            commit('ACCOUNT_ERROR')
            reject(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR', resp.payload.error)
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject(resp.payload.error)
          }
        })
        .catch(err => {
          commit('ACCOUNT_ERROR', err)
          reject(err)
        })
    })
  },
}

const mutations = {
  ['ACCOUNT_JOIN']: (state) => {
    state.status = 'joining'
  },
  ['ACCOUNT_JOIN_SUCCESS']: (state) => {
    state.status = 'success'
  },
  ['ACCOUNT_SUCCESS']: (state, resp) => {
    state.status = 'success'
    state.user = resp.user
  },
  ['ACCOUNT_UPDATE_SUCCESS']: (state) => {
    state.status = 'success'
  },
  ['ACCOUNT_ERROR']: (state) => {
    state.status = 'error'
  },
  ['ACCOUNT_EMAIL_ERROR']: (state) => {
    state.status = 'emailError'
  },
  ['ACCOUNT_PASSWORD_ERROR']: (state) => {
    state.status = 'passwordError'
  },
  ['ACCOUNT_EMAIL_CONFIRM']: (state) => {
    state.status = 'confirming'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
