import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import moment from 'moment'
import router from './router'
import store from './store'
import { VueMaskDirective } from 'v-mask'

Vue.config.productionTip = false

Vue.prototype.$moment = moment

Vue.directive('mask', VueMaskDirective)

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App),
  methods: {
    moment: function () {
      return moment()
    }
  },
  mounted: function () {
    this.$moment.locale('ru')
  }
}).$mount('#app')
