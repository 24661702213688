import router from '../../router'
import apiCall from '../../plugins/api'

const state = {
  status: '',
  days: [],
  day: {}
}

const getters = {
  daysStatus: state => state.status,
  days: state => state.days,
  day: state => state.day
}

const actions = {
  ['DAYS_GET']: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/days', data: {} , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('DAYS_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('DAYS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('DAYS_ERROR')
          resolve(err)
        })
    })
  },
  ['DAYS_UPDATE']: ({ commit, dispatch }, day) => {
    return new Promise((resolve, reject) => {
      commit('DAYS_UPDATE')
      apiCall({ url: '/api/days/edit', data: day, method: 'POST' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('DAYS_UPDATE_SUCCESS')
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('DAYS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('DAYS_ERROR', err)
          reject(err)
        })
    })
  },
  ['DAYS_GET_DAY']: ({ commit, dispatch }, day) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/days/' + day.id, data: {} , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('DAYS_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('DAYS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('DAYS_ERROR')
          resolve(err)
        })
    })
  },
  ['DAYS_SET']: ({ commit }, day) => {
    return new Promise((resolve) => {
      commit('DAYS_SET', day)
      resolve()
    })
  }
}

const mutations = {
  ['DAYS_UPDATE']: (state) => {
    state.status = 'updating'
  },
  ['DAYS_UPDATE_SUCCESS']: (state) => {
    state.status = 'success'
  },
  ['DAYS_SUCCESS']: (state, resp) => {
    state.status = 'success'
    state.days = resp.days || []
    state.day = resp.day || {}

  },
  ['DAYS_ERROR']: (state) => {
    state.status = 'error'
  },
  ['DAYS_SET']: (state, day) => {
    state.day = day
  },
  ['DAYS_CLEAN']: (state) => {
    state.days = []
    state.day = {}
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
