<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="backButton()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title>Изменение года</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="editYear()" :disabled="!formValid" v-on="on">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </template>
        <span>Сохранить</span>
      </v-tooltip>
    </v-app-bar>
    <v-container>
      <v-row justify="center">
        <v-col xl="5" lg="7" md="9" sm="11">
          <v-form lazy-validation ref="yearForm" v-model="formValid">
            <p>Год: {{ obj.id }}</p>
            <v-textarea label="В плюсе"
              v-model.trim="obj.in_plus"
              auto-grow
              :rules="[v => !!v || 'Пожалуйста, заполните это поле']">
            </v-textarea>
            <v-textarea label="В минусе"
              v-model.trim="obj.in_minus"
              auto-grow
              :rules="[v => !!v || 'Пожалуйста, заполните это поле']">
            </v-textarea>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data () {
    return {
      obj: {},
      formValid: false
    }
  },
  methods: {
    backButton: function () {
      this.$store.commit('YEARS_CLEAN')
      this.$router.go(-1)
    },
    editYear: function () {
      if (this.$refs.yearForm.validate()) {
        this.$store.dispatch('YEARS_UPDATE', this.obj).then(() => {
          if (this.$store.getters.yearsStatus === 'success') {
            this.$store.commit('SNACKBAR_SET', 'Сохранено успешно')
            this.backButton()
          }
        }, (err) => {
          this.$store.commit('SNACKBAR_SET', 'При сохранении произошла ошибка.' + err)
          this.backButton()
        })
      }
    },
    getYear: function () {
      this.$store.dispatch('YEARS_GET_YEAR', this.$store.getters.year).then(() => {
        if (this.$store.getters.yearsStatus === 'success') {
          this.obj = this.$store.getters.year
        } else {
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
          this.backButton()
        }
      }, (err) => {
        err = ''
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
        this.backButton()
      })
    }
  },
  mounted: function () {
    try {
      this.obj = this.$store.getters.year
      if (this.obj.id !== null) this.getYear()
    } catch (e) {
      this.backButton()
    }
  }
}
</script>

<style scoped>

</style>
