<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="backButton()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title><template v-if="calendar.id === null">Создать</template><template v-else>Изменить</template> календарь</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip left v-if="state === 'edit'">
        <template v-slot:activator="{ on }">
          <v-btn icon @click="editObj()" :disabled="!formValid" v-on="on">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </template>
        <span>Сохранить</span>
      </v-tooltip>
    </v-app-bar>
    <v-container>
      <v-row justify="center">
        <v-col xl="5" lg="7" md="9" sm="11">
          <v-form v-if="state === 'edit'" lazy-validation ref="calendarForm" v-model="formValid">
            <p v-if="calendar.id !== null"><a :href="calendar.link" target="_blank">{{ calendar.link }}</a></p>
            <v-text-field clearable
              v-model="dateSince"
              label="Календарь с"
              @click:clear="calendar.date_since = ''"
              v-mask="dateMask"
              :rules="[ v => !!v || 'Пожалуйста, заполните это поле',
                        v => /^(0?[1-9]|[12][0-9]|3[01])[\.](0?[1-9]|1[012])[\.]\d{4}$/.test(v) || 'Укажите правильную дату',
                        v => (dateTill !== '' && $moment(v, 'DD.MM.YYYY').isBefore($moment(dateTill, 'DD.MM.YYYY').format('YYYY-MM-DD'))) || 'Дата начала действия должна быть раньше']">
            ></v-text-field>
            <v-text-field clearable
              v-model="dateTill"
              label="Календарь по"
              @click:clear="calendar.date_till = ''"
              v-mask="dateMask"
              :rules="[ v => !!v || 'Пожалуйста, заполните это поле',
                        v => /^(0?[1-9]|[12][0-9]|3[01])[\.](0?[1-9]|1[012])[\.]\d{4}$/.test(v) || 'Укажите правильную дату',
                        v => (dateSince !== '' && $moment(v, 'DD.MM.YYYY').isAfter($moment(dateSince, 'DD.MM.YYYY').format('YYYY-MM-DD'))) || 'Дата конца действия должна быть позже']">
            ></v-text-field>
            <v-text-field
              v-model="calendar.last_name"
              label="Фамилия"
              :rules="[ v => !!v || 'Пожалуйста, заполните это поле']">
            </v-text-field>
            <v-text-field
              v-model="calendar.name"
              label="Имя"
              :rules="[ v => !!v || 'Пожалуйста, заполните это поле']">
            </v-text-field>
            <v-text-field clearable
              v-model="birthDate"
              label="Дата рождения"
              @click:clear="calendar.birth_date = ''"
              v-mask="dateMask"
              :rules="[ v => !!v || 'Пожалуйста, заполните это поле',
                        v => /^(0?[1-9]|[12][0-9]|3[01])[\.](0?[1-9]|1[012])[\.]\d{4}$/.test(v) || 'Укажите правильную дату']">
            ></v-text-field>
          </v-form>
          <v-alert v-if="state === 'success'">
            <p>Успешно: <a :href="$store.getters.calendarLink" target="_blank">{{ $store.getters.calendarLink }}</a> <v-btn icon @click="copyLink()"><v-icon>mdi-content-copy</v-icon></v-btn></p>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data () {
    return {
      state: 'edit',
      calendar: {
        date_since: this.$store.getters.calendar.date_since || this.$moment().format('YYYY-MM-DD'),
        date_till: this.$store.getters.calendar.date_till || this.$moment().add(1, 'y').subtract(1, 'd').format('YYYY-MM-DD')
      },
      formValid: false,
      dateMask: '##.##.####',
      dateSince: '',
      dateTill: '',
      birthDate: ''
    }
  },
  watch: {
    dateSince: function (newDate) {
      this.calendar.date_since = this.$moment(newDate, 'DD.MM.YYYY').format('YYYY-MM-DD')
    },
    dateTill: function (newDate) {
      this.calendar.date_till = this.$moment(newDate, 'DD.MM.YYYY').format('YYYY-MM-DD')
    },
    birthDate: function (newDate) {
      this.calendar.birth_date = this.$moment(newDate, 'DD.MM.YYYY').format('YYYY-MM-DD')
    },
  },
  methods: {
    backButton: function () {
      this.$store.commit('CALENDARS_BOTTOMSHEET_CLOSE')
      this.$store.commit('CALENDARS_BOTTOMSHEET_CLEAN')
      this.$router.go(-1)
    },
    editObj: function () {
      if (this.$refs.calendarForm.validate()) {
        this.formValid = false
        this.$store.dispatch('CALENDARS_UPDATE', this.calendar).then(() => {
          if (this.$store.getters.calendarsStatus === 'success') {
            this.state = 'success'
          } else {
            this.$store.commit('SNACKBAR_SET', 'При сохранении произошла ошибка')
            this.backButton()
          }
        }, (err) => {
          this.$store.commit('SNACKBAR_SET', 'При сохранении произошла ошибка.' + err)
          this.backButton()
        })
      }
    },
    copyLink: function () {
      navigator.clipboard.writeText(this.$store.getters.calendarLink);
      this.$store.commit('SNACKBAR_SET', 'Ссылка скопирована в буфер обмена')
    }
  },
  mounted: function () {
    if (!this.$store.getters.isAuthenticated) this.$router.push('/login/')
    if (this.$store.getters.calendar.id === undefined) this.backButton()
    if (this.$store.getters.calendar.id !== null) {
      this.calendar = this.$store.getters.calendar
    }
    this.dateSince = this.calendar.date_since ? this.$moment(this.calendar.date_since).format('DD.MM.YYYY') : ''
    this.dateTill = this.calendar.date_till ? this.$moment(this.calendar.date_till).format('DD.MM.YYYY') : ''
  }
}
</script>

<style scoped>

</style>
