import router from '../../router'
import apiCall from '../../plugins/api'

const state = {
  status: '',
  months: [],
  month: {}
}

const getters = {
  monthsStatus: state => state.status,
  months: state => state.months,
  month: state => state.month
}

const actions = {
  ['MONTHS_GET']: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/months', data: {} , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('MONTHS_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('MONTHS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('MONTHS_ERROR')
          resolve(err)
        })
    })
  },
  ['MONTHS_UPDATE']: ({ commit, dispatch }, month) => {
    return new Promise((resolve, reject) => {
      commit('MONTHS_UPDATE')
      apiCall({ url: '/api/months/edit', data: month, method: 'POST' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('MONTHS_UPDATE_SUCCESS')
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('MONTHS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('MONTHS_ERROR', err)
          reject(err)
        })
    })
  },
  ['MONTHS_GET_MONTH']: ({ commit, dispatch }, month) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/months/' + month.id, data: {} , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('MONTHS_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('MONTHS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('MONTHS_ERROR')
          resolve(err)
        })
    })
  },
  ['MONTHS_SET']: ({ commit }, month) => {
    return new Promise((resolve) => {
      commit('MONTHS_SET', month)
      resolve()
    })
  }
}

const mutations = {
  ['MONTHS_UPDATE']: (state) => {
    state.status = 'updating'
  },
  ['MONTHS_UPDATE_SUCCESS']: (state) => {
    state.status = 'success'
  },
  ['MONTHS_SUCCESS']: (state, resp) => {
    state.status = 'success'
    state.months = resp.months || []
    state.month = resp.month || {}

  },
  ['MONTHS_ERROR']: (state) => {
    state.status = 'error'
  },
  ['MONTHS_SET']: (state, month) => {
    state.month = month
  },
  ['MONTHS_CLEAN']: (state) => {
    state.months = []
    state.month = {}
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
