<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="menu = !menu">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-toolbar-title>Мои календари</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="newCalendar()" v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Создать календарь</span>
      </v-tooltip>
    </v-app-bar>
    <v-container>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Найти"
        @input="updateProps()"
      ></v-text-field>
      <v-data-table
        fixed-header
        :headers="listHeaders"
        :items="listData.data"
        :options.sync="listOptions"
        :server-items-length="listData.total"
        :loading="dataLoading"
        :footer-props="listFooterProps"
        @update:options="updateProps()"
        >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <text-highlight :queries="searchArray">{{ $moment(item.created_at).format('DD.MM.YYYY') }}</text-highlight>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ $moment(item.date_since).format('DD.MM.YYYY') }}</text-highlight>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ $moment(item.date_till).format('DD.MM.YYYY') }}</text-highlight>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ item.name }}</text-highlight>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ item.last_name }}</text-highlight>
            </td>
            <td>
              <text-highlight :queries="searchArray">{{ $moment(item.birth_date).format('DD.MM.YYYY') }}</text-highlight>
            </td>
            <td>
              {{ item.sense_number }}
            </td>
            <td>
              {{ item.mission }}
            </td>
            <td>
              <v-btn icon @click.stop="showBottomsheet(item)">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-bottom-sheet v-model="bottomSheetVisible">
        <v-list>
          <v-list-item @click="copyLink()">
            <v-list-item-avatar>
              <v-icon>mdi-content-copy</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Скопировать ссылку</v-list-item-title>
          </v-list-item>
          <v-list-item @click="viewObj()">
            <v-list-item-avatar>
              <v-icon>mdi-eye-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Открыть</v-list-item-title>
          </v-list-item>
          <v-list-item @click="editObj()">
            <v-list-item-avatar>
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Изменить</v-list-item-title>
          </v-list-item>
          <v-list-item @click="removeObjConfirm()">
            <v-list-item-avatar>
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Удалить</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-bottom-sheet>
      <v-dialog v-model="dialogVisible" max-width="300">
        <v-card>
          <v-card-text class="text-center py-6">Удалить календарь?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="removeObj()">Да</v-btn>
            <v-btn text @click="removeClean()">Нет</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import TextHighlight from 'vue-text-highlight'

export default {
  data () {
    return {
      listHeaders: [
        { text: 'Дата создания', value: 'created_at' },
        { text: 'Календарь с', value: 'date_since' },
        { text: 'Календарь по', value: 'date_till' },
        { text: 'Имя', value: 'name' },
        { text: 'Фамилия', value: 'last_name' },
        { text: 'Дата рождения', value: 'birth_date' },
        { text: 'Число сознания', value: 'sense_number', sortable: false, filterable: false },
        { text: 'Миссия', value: 'mission', sortable: false, filterable: false },
        { text: '', value: 'actions', sortable: false, filterable: false }
      ],
      listData: [],
      dataLoading: false,
      listOptions: {
        itemsPerPage: 25,
        sortBy: ['id'],
        sortDesc: [false],
        mustSort: true,
        multiSort: false
      },
      listFooterProps: {
        showFirstLastPage: true,
        itemsPerPageOptions: [25, 50, 100, 250]
      },
      search: '',
      fields: []
    }
  },
  computed: {
    menu: {
      get: function () {
        return this.$store.getters.menuIsOpen
      },
      set: function (value) {
        this.$store.commit('MENU_SET', value)
      }
    },
    searchArray: function () {
      return this.search.split(' ').filter(function (s) { return s })
    },
    bottomSheetVisible: {
      get: function () {
        return this.$store.getters.isCalendarsBottomsheetOpen
      },
      set: function () {
        this.$store.commit('CALENDARS_BOTTOMSHEET_CLOSE')
        this.$store.commit('CALENDARS_BOTTOMSHEET_CLEAN')
      }
    },
    dialogVisible: {
      get: function () {
        return this.$store.getters.isDialogOpen
      },
      set: function () {
        this.$store.commit('DIALOG_CLOSE')
      }
    }
  },
  methods: {
    loadData: function () {
      this.dataLoading = true
      let options = {
        page: this.listOptions.page,
        itemsPerPage: this.listOptions.itemsPerPage,
        sortBy: this.listOptions.sortBy,
        sortDesc: this.listOptions.sortDesc,
        search: this.search
      }
      this.$store.dispatch('CALENDARS_GET', options).then(() => {
        this.dataLoading = false
        if (this.$store.getters.calendarsStatus === 'success') {
          this.listData = this.$store.getters.calendars
        } else {
          this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки')
        }
      }, (err) => {
        this.dataLoading = false
        err = ''
        this.$store.commit('SNACKBAR_SET', 'Ошибка загрузки' + err)
      })
    },
    updateProps: function () {
      if (this.listOptions.sortBy.length === 1) this.listOptions.mustSort = true
      else this.listOptions.mustSort = false
      this.loadData()
    },
    showBottomsheet: function (calendar) {
      this.$store.dispatch('CALENDARS_BOTTOMSHEET_OPEN', calendar)
    },
    viewObj: function () {
      this.$store.commit('CALENDARS_BOTTOMSHEET_CLOSE')
      window.open(this.$store.getters.calendar.link, '_blank')
    },
    editObj: function () {
      this.$store.commit('CALENDARS_BOTTOMSHEET_CLOSE')
      this.$router.push('/calendars/edit/')
    },
    removeClean: function () {
      this.dialogVisible = false
      this.$store.commit('CALENDARS_BOTTOMSHEET_CLEAN')
    },
    removeObj: function () {
      this.$store.commit('DIALOG_CLOSE')
      this.$store.dispatch('CALENDARS_REMOVE', { id: this.$store.getters.calendar.id }).then(() => {
        if (this.$store.getters.calendarsStatus === 'success') {
          this.loadData()
          this.$store.commit('SNACKBAR_SET', 'Удалено успешно')
        } else {
          this.$store.commit('SNACKBAR_SET', 'При удалении произошла ошибка')
        }
      }, (err) => {
        this.$store.commit('SNACKBAR_SET', 'При удалении произошла ошибка. ' + err)
      }).then(() => {
        this.$store.commit('CALENDARS_BOTTOMSHEET_CLOSE')
        this.$store.commit('CALENDARS_BOTTOMSHEET_CLEAN')
      })
    },
    removeObjConfirm: function () {
      this.$store.commit('CALENDARS_BOTTOMSHEET_CLOSE')
      this.$store.dispatch('DIALOG_OPEN', this.$store.getters.calendar)
    },
    newCalendar: function () {
      this.$store.dispatch('CALENDARS_SET', { id: null }).then(() => {
        this.$router.push('/calendars/edit/')
      })
    },
    copyLink: function () {
      navigator.clipboard.writeText(this.$store.getters.calendar.link);
      this.$store.commit('SNACKBAR_SET', 'Ссылка скопирована в буфер обмена')
    },
  },
  mounted: function () {
    if (!this.$store.getters.isAuthenticated) this.$router.push('/login/')
  },
  components: {
    TextHighlight
  }
}
</script>

<style scoped>

</style>
