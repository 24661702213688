import axios from 'axios'

const apiCall = ({ url, method, data }) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['Authorization'] = localStorage.getItem('token') || ''
  if (navigator.onLine) {
    switch (method) {
      case 'POST':
        axios.post(url, data)
        .then(function (response) {
          if (response) {
              resolve(response.data)
          }
        })
        .catch(err => {
          reject(err)
        })
        break
      case 'GET':
        axios.get(url + '?' + new URLSearchParams(data).toString())
        .then(function (response) {
          if (response) {
            resolve(response.data)
          }
        })
        .catch(err => {
          reject(err)
        })
        break
    }
  } else {
    resolve({ status: 'offlineError' })
  }
})

export default apiCall
