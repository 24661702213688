import router from '../../router'
import apiCall from '../../plugins/api'

const state = {
  status: '',
  years: [],
  year: {}
}

const getters = {
  yearsStatus: state => state.status,
  years: state => state.years,
  year: state => state.year
}

const actions = {
  ['YEARS_GET']: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/years', data: {} , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('YEARS_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('YEARS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('YEARS_ERROR')
          resolve(err)
        })
    })
  },
  ['YEARS_UPDATE']: ({ commit, dispatch }, year) => {
    return new Promise((resolve, reject) => {
      commit('YEARS_UPDATE')
      apiCall({ url: '/api/years/edit', data: year, method: 'POST' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('YEARS_UPDATE_SUCCESS')
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('YEARS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('YEARS_ERROR', err)
          reject(err)
        })
    })
  },
  ['YEARS_GET_YEAR']: ({ commit, dispatch }, year) => {
    return new Promise((resolve, reject) => {
      apiCall({ url: '/api/years/' + year.id, data: {} , method: 'GET' })
        .then(resp => {
          if (resp.status === 'success') {
            commit('YEARS_SUCCESS', resp)
            resolve(resp)
          } else if (resp.status === 'authError') {
            commit('AUTH_ERROR')
            dispatch('AUTH_LOGOUT')
              .then(() => {
                router.push('/login/')
              })
            reject()
          } else {
            commit('YEARS_ERROR')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('YEARS_ERROR')
          resolve(err)
        })
    })
  },
  ['YEARS_SET']: ({ commit }, year) => {
    return new Promise((resolve) => {
      commit('YEARS_SET', year)
      resolve()
    })
  }
}

const mutations = {
  ['YEARS_UPDATE']: (state) => {
    state.status = 'updating'
  },
  ['YEARS_UPDATE_SUCCESS']: (state) => {
    state.status = 'success'
  },
  ['YEARS_SUCCESS']: (state, resp) => {
    state.status = 'success'
    state.years = resp.years || []
    state.year = resp.year || {}

  },
  ['YEARS_ERROR']: (state) => {
    state.status = 'error'
  },
  ['YEARS_SET']: (state, year) => {
    state.year = year
  },
  ['YEARS_CLEAN']: (state) => {
    state.years = []
    state.year = {}
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
